import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { SectionTitle  } from '../../styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ArrowLeftOutlined from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlined from '@mui/icons-material/ArrowRightOutlined';
import UserContext from "../../context/UserContext";
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Button, 
    Grid, 
    MenuItem, 
    Select, 
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Snackbar,
    Alert,
    AlertTitle
} from '@mui/material';    
import { getNoticeList, deleteNotice } from "../../services/notice";
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';    

const NoticesList = () => {
    const { state, setState } = useContext(UserContext);
    const [noticeList, setNoticeList] = useState(null);
    const [noticeDelete, setNoticeDelete] = useState(null);
    const [message, setMessage] = useState({
        open: false,
        message: '',
        type: 'success'
    });
    const [pages, setPageSelected] = useState({
        pageSelected: 1,
        totalPages: 1
    });

    const navigate = useNavigate();
    const listPage = () => {
        const listSelect = [<MenuItem value="1">1</MenuItem>];

        if(pages.totalPages <= 1)
            return listSelect;

        for(let i = 2; i <= pages.totalPages; i++){
            listSelect.push((
                <MenuItem value={i}>{i}</MenuItem>
            ));
        }
        return listSelect;
    }

    const selectPage = (pageSelected) => {
        setPageSelected({...pages, pageSelected});

        getNoticeList(pageSelected).then(({data}) => {
            setNoticeList(data);
        });
    }

    const clickEditNotice = noticeEdit => {
        setState({...state, notice_edit: noticeEdit});
        navigate(`/area-administrativa/editar-aviso/${noticeEdit.id}`);
    }


    const [open, setOpen] = useState(false);

    const handleClickOpen = (notice) => {
        setNoticeDelete(notice);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setState({ ...state, loading: true });
        deleteNotice(noticeDelete.id).then(() => {
            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: 'Registro excluído com sucesso!',
                type: 'success'
            });
            setPageSelected({...pages, pageSelected: 1})
            getListNotice();
            handleClose();
        }).catch(() => {
            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: 'Ocorreu um erro ao excluir o registro, tente novamente mais tarde!',
                type: 'error'
            });
            handleClose();
        });
    }

    const handleNewNotice = () => {
        setState({...state, notice_edit: null});
        navigate('/area-administrativa/cadastro-aviso')
    }

    const getListNotice = () => {
        getNoticeList(pages.pageSelected).then(({data}) => {
            setNoticeList(data);

            let totalPages = Math.ceil((data.total / 10));
            totalPages = totalPages < 1 ? 1 : totalPages;
            setPageSelected({...pages, totalPages})
        });
    }

    useEffect(() => {
        setState({
            ...state,
            breadcrumb: [
              {
                text: "Home",
                link: "/",
              },
              { text: "Área Administrativa", link: "/area-administrativa" },
              { text: "Lista de Avisos" },
            ],
        });

        getListNotice();
    }, []);

    return (
        <Grid container spacing={2}>
            <Snackbar open={message.open} autoHideDuration={15000} 
                onClose={() => {setMessage({...message, open: false});}} 
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                <Alert severity={message.type}>
                    <AlertTitle>{message.message}</AlertTitle>
                </Alert>
            </Snackbar>
            <Grid item xs={12} md={12} style={{textAlign: 'center', marginTop: '30px'}}>
                <SectionTitle>Lista de Avisos</SectionTitle>
            </Grid>

            <Grid item xs={12} md={12} style={{marginTop: '40px'}}>
                <Button 
                    size="small" 
                    variant="contained" 
                    className="button-primary-default"
                    onClick={() => handleNewNotice()}> 
                    Adicionar Aviso
                </Button>
            </Grid>

            <Grid item xs={12} md={12}  >
                <TableContainer style={{marginTop: '20px'}}>
                    <Table sx={{ minWidth: 650 }} className='table-full-width'>
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">Título</TableCell>
                            <TableCell align="center">Descrição</TableCell>
                            <TableCell align="center">Início</TableCell>
                            <TableCell align="center">Fim</TableCell>
                            <TableCell align="center">Restrito</TableCell>
                            <TableCell align="center">Ação</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {noticeList && noticeList.resultado.map(item =>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left">
                                        {`${item.titulo.substr(0, 50)}${item.titulo.length > 50 ? '...' : ''}`}
                                    </TableCell>
                                    <TableCell align="center">
                                        {`${item.descricao.substr(0, 50)}${item.descricao.length > 50 ? '...' : ''}`}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dayjs(item.inicio, 'DDMMYYYY').format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {dayjs(item.fim, 'DDMMYYYY').format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.restrito ? 'Sim' : 'Não'}
                                    </TableCell>
                                    <TableCell align="center">
                                        <span className='icon-table' title="Excluir" onClick={() => handleClickOpen(item)}><DeleteForeverIcon /></span>
                                        <span className='icon-table' title="Editar" onClick={() => clickEditNotice(item)}><EditIcon /></span>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`Deseja realmente excluir o registro?`}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="h6" gutterBottom>{noticeDelete ? noticeDelete.titulo : ""}</Typography>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Excluir
                    </Button>
                    </DialogActions>
                </Dialog>
            </Grid>

            <Grid item xs={12} md={12} style={{marginBottom: '100px', textAlign: 'center'}}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{display: 'block'}}>
                    <Button variant="outlined" size="small" disabled={pages.pageSelected === 1} onClick={() => {selectPage(pages.pageSelected - 1)}}
                        style={{margin: '10px'}}>
                        <ArrowLeftOutlined />
                    </Button>
                    <Select id="demo-customized-select" value={pages.pageSelected} onChange={(event) => {selectPage(parseInt(event.target.value))}}>
                        {noticeList && listPage().map((item) => item)}
                    </Select>
                    <Button variant="outlined" size="small" disabled={pages.pageSelected === pages.totalPages} onClick={() => {selectPage(pages.pageSelected + 1)}}
                        style={{margin: '10px'}}>
                        <ArrowRightOutlined />
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default NoticesList;