import { api } from './api';

const getUH = () => {
  const token = localStorage.getItem('token');

  return api.get("/uh", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export { getUH };
