import { useEffect, useState, useContext, useRef } from "react";

import {
    MenuItem,
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Grid,
    Switch,
    Select,
    Snackbar,
    Alert,
    AlertTitle,
    DialogContentText,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { SectionTitle } from '../../styles';
import ArrowLeftOutlined from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlined from '@mui/icons-material/ArrowRightOutlined';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import { useParams } from "react-router-dom";
import { getBankSlipListOwner, enableBankSlipSendEmail, sendEmailBankSlipUH, downloadBankSlipUH } from "../../services/bankslip";
import UserContext from "../../context/UserContext";
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

const BankSlipOwners = () => {
    let { id } = useParams();
    const [bankslipList, setBankslipList] = useState(null);
    const [referenceMonth, setReferenceMonth] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [uhSendEmail, setUhSendEmail] = useState('');
    const { state, setState } = useContext(UserContext);
    const [pages, setPageSelected] = useState({
        pageSelected: 1,
        totalPages: 1
    });
    const [message, setMessage] = useState({
        open: false,
        message: '',
        type: 'success'
    });

    const listPage = () => {
        const listSelect = [<MenuItem value="1">1</MenuItem>];

        if(pages.totalPages <= 1)
            return listSelect;

        for(let i = 2; i <= pages.totalPages; i++){
            listSelect.push((
                <MenuItem value={i}>{i}</MenuItem>
            ));
        }
        return listSelect;
    }

    useEffect(() => {
        setState({
            ...state,
            breadcrumb: [
              {
                text: "Home",
                link: "/",
              },
              { text: "Área Administrativa", link: "/area-administrativa" },
              { text: "Lista de Arquivos de Boletos", link: "/area-administrativa/lista-arquivos-boletos" },
              { text: "Lista de Boletos" },
            ],
        });

        getListBankSlip(1);
    }, []);

    const getListBankSlip = pageSelected => {
        getBankSlipListOwner(id, pageSelected).then(({data}) => {
            setBankslipList(data);
            const refMonth = data.resultado[0].data;
            setReferenceMonth(refMonth);

            let totalPages = Math.ceil((data.total / 10));
            totalPages = totalPages < 1 ? 1 : totalPages;
            setPageSelected({totalPages, pageSelected});
        });
    }

    const handleEnableEmail = (bankSlipId, checked, indexList) => {
        setState({ ...state, loading: true });
        enableBankSlipSendEmail(bankSlipId, checked).then(() => {
            bankslipList.resultado[indexList].flag = !bankslipList.resultado[indexList].flag;
            setBankslipList(bankslipList);

            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: `Envio ${checked ? 'habilitado' : 'desabilitado'}!`,
                type: 'success'
            });
        }).catch(() => {
            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: `Ocorreu um erro ao  ${checked ? 'habilitado' : 'desabilitado'}!`,
                type: 'error'
            });
        });
    };

    const handleSendEmailUH = () => {
        setState({ ...state, loading: true });
        setOpenDialog(false);
        
        const refMonth = dayjs(referenceMonth, 'DD/MM/YYYY').format('MMYYYY');

        sendEmailBankSlipUH(uhSendEmail.uh, refMonth).then(() => {
            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: `E-mail enviado para a UH: ${uhSendEmail.uh}.`,
                type: 'success'
            });
            getListBankSlip(pages.pageSelected);
        }).catch(() => {
            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: `Ocorreu um erro ao enviar e-mail para a UH: ${uhSendEmail.uh}!`,
                type: 'error'
            });
        });
    };


    const handleSelectUHSendEmail = (bankSlipOwner) => {
        setUhSendEmail(bankSlipOwner);
        setOpenDialog(true);
    }

    const downloadPDF = (bankslip) => {
        downloadBankSlipUH(bankslip.id).then(response => {
            const newBlob = new Blob([response.data], { type: "application/pdf" });
            const data = window.URL.createObjectURL(newBlob);

            window.open(data);
        }).catch(() => {
            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: 'Ocorreu um erro ao abrir o arquivo, tente novamente mais tarde!',
                type: 'error'
            });
        });
    }

    return (
        <Grid container spacing={2}>
            <Snackbar open={message.open} autoHideDuration={15000} 
                onClose={(event) => setMessage({...message, open: false})} 
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                <Alert severity={message.type}>
                    <AlertTitle>{message.message}</AlertTitle>
                </Alert>
            </Snackbar>
            <Grid item xs={12} md={12} style={{textAlign: 'center', marginTop: '30px'}}>
                <SectionTitle>Lista de Arquivos de Boletos</SectionTitle>
            </Grid>

            <Grid item xs={12} md={12} style={{textAlign: 'center', marginTop: '30px'}}>
                <Typography variant="h5" gutterBottom>Mês de referência: {dayjs(referenceMonth, 'DD/MM/YYYY').format('MM/YYYY')}</Typography>
            </Grid>

            <Grid item xs={12} md={12}>
                <TableContainer style={{marginTop: '20px'}}>
                    <Table sx={{ minWidth: 650 }} className='table-full-width'>
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">UH</TableCell>
                            <TableCell align="center">Envio de e-mail habilitado</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Ação</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {bankslipList !== null && bankslipList.resultado.map((item, key) => 
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center">
                                        {item.nome}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.uh}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Switch checked={item.flag} size="small" onChange={(event) => handleEnableEmail(item.id, event.target.checked, key)}/>
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.dataEnvio === null ? 'Não enviado' : `Enviado em: ${dayjs(item.dataEnvio,  'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')}`}
                                    </TableCell>
                                    <TableCell align="center">
                                        <span className='icon-table' title="Enviar E-mails" onClick={() => handleSelectUHSendEmail(item)}><SendIcon /></span>
                                        <span className='icon-table' title="Download PDF" onClick={() => downloadPDF(item)}><DownloadIcon /></span>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Enviar e-mail de boleto para a UH {uhSendEmail.uh}?
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="h6" gutterBottom>
                         Deseja realmente enviar o e-mail de boleto referente ao mês <b>{dayjs(referenceMonth, 'DD/MM/YYYY').format('MM/YYYY')}</b> para a UH <b>{uhSendEmail.uh}</b>?
                        </Typography>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button onClick={() => handleSendEmailUH()}>Enviar</Button>
                    </DialogActions>
                </Dialog>
            </Grid>

            <Grid item xs={12} md={12} style={{marginBottom: '100px', textAlign: 'center'}}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{display: 'block'}}>
                    <Button variant="outlined" size="small" disabled={pages.pageSelected === 1} onClick={() => {getListBankSlip(pages.pageSelected - 1)}}
                        style={{margin: '10px'}}>
                        <ArrowLeftOutlined />
                    </Button>
                    <Select id="demo-customized-select" value={pages.pageSelected} onChange={(event) => {getListBankSlip(parseInt(event.target.value))}}>
                        {bankslipList && listPage().map((item) => item)}
                    </Select>
                    <Button variant="outlined" size="small" disabled={pages.pageSelected === pages.totalPages} onClick={() => {getListBankSlip(pages.pageSelected + 1)}}
                        style={{margin: '10px'}}>
                        <ArrowRightOutlined />
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default BankSlipOwners;