import logo from "../assets/logo_institucional_azul.png";

const downloadStatement = (data, my, ownerName) => {
  const isNeg = (value) => value.includes('-');

  const colorValue = (value) => (isNeg(value) ? "#8D0B0B" : "#13395C");

  const formattedValue = (value) => {
    const my = value.replace(/[^0-9]/g, '');
    const y = my.slice(0, 4);
    let m = my.slice(4, 6);
    return `${m}/${y}`;
  };

  const page = window.open("", "", "height=fit-content, width: 500px");
  page.document.write(
    `<html>
    <head>
      <style>
        @import "https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&display=swap";
        html, body { font-family: 'Montserrat', sans-serif; }
        td { font-size: 14px;}
        th {padding: 14px 10px;}
        .data-col{
          float: left;
          position: relative;
        }
        .data-box{
          display: flex;
          float: left;
          height: 100px;
          text-align: center;
          width: 100%;
        }
        .data-box h3, h5{margin: auto}
        .data-box h3{color: #003361;font-weight: normal;}
      </style>
    </head>`
  );

  page.document.title = `Extrato de locação | Ref ${formattedValue(my)}`;
  page.document.write(`<body style="font-family: 'Montserrat', sans-serif;" >`);
  page.document.write(`
  <div style="text-align: center; width: 100%; padding-top: 2em; display: flex;">
    <div class="data-col" style="width: 33%;">
      <div class="data-box">
        <div style="width: 100%;margin: auto;">
          <img id="logo" alt="logo" style="height: 100px;"/>
        </div>
      </div>
    </div>
    <div class="data-col" style="width: 33%;">
      <div class="data-box">
        <h3>Extrato de locação</h3>
      </div>
    </div>
    <div class="data-col" style="width: 33%;">
      <div class="data-box">
        <h5>${ownerName}</h5>
      </div>
    </div>
  </div>
  <div>
    <h5 style="text-align: center;">${formattedValue(my)}</h5>
  </div>
  `);

  const logoEl = page.document.getElementById("logo");
  logoEl.setAttribute("src", logo);

  page.document.write(`<div style="width: 100%;display: flex;">`);
  page.document.write(
    `<table style="width: 100%;background-color: transparent;border-spacing: 0 1em; padding: 3em; margin: auto;"><thead style="text-align: left; width: 100%; color: #444;">`
  );
  //table header
  page.document.write(`<tr style="margin-top: 40px;">`);
  page.document.write(`<th style="width: 85px;">Apto</th>`);
  page.document.write(`<th style="width: 100px;">Data</th>`);
  page.document.write(`<th style="width: 400px;">Descrição</th>`);
  page.document.write(
    `<th style="width: 130px;">Valor</th></tr></thead>`
  );

  //table body

  page.document.write(`<tbody>`);
  if(data){
    data.map((item) => {
      page.document.write(`<tr style="width: 100px; margin-top: 40px;">`);

      page.document.write(
        `<td style="width: 85px; font-weight: 700; margin-bottom: .5em; border-bottom: 1px solid #EEEEEE; padding: 14px 10px;">${item.Apto}</td>`
      );
      
      page.document
        .write(`<td style="width: 100px; flex-direction: column;border-bottom: 1px solid #EEEEEE; padding: 14px 10px;">${item.Data}</td>`);
      
      page.document.write(
        `<td style="width: 400px; font-weight: 700; margin-bottom: .5em; border-bottom: 1px solid #EEEEEE; padding: 14px 10px;">${item.Historico}</td>`
      );

      page.document.write(
        `<td style="width: 130px; border-bottom: 1px solid #EEEEEE; padding: 14px 10px; color:${colorValue(item.Valor)}">
          ${item.Valor}
        </td>`
      );
    });
  }

  page.document.write(`</tr></tbody>`);
  page.document.write(`</table>`);
  page.document.write(`</div>`);
  page.document.write(`</body></html>`);

  page.document.close();
  page.focus();
  
  page.onload = () => {
    page.print();
    page.onafterprint = page.close();
  }
};

export { downloadStatement };
