import { HeaderImage, HeaderStyle, HeaderTitle, ListIcon } from "./styles";
import { FlexItem, LogoWhite } from "../../styles";
import ProfileMenu from "./ProfileMenu";
import { useNavigate } from "react-router-dom";
import {Grid, Link} from '@mui/material';

const Header = ({ logged, onMenuClick }) => {
  const navigate = useNavigate();

  return (
    <HeaderImage>
      <HeaderStyle>
        <FlexItem flex height="100%" width="100%" margin="auto">
          {logged && <ListIcon onClick={() => onMenuClick()} />}
          <div style={{ marginLeft: '100px'}}>
            <LogoWhite
              onClick={() => navigate("/")}
              margin="auto"
              width="140px"
              height="140px"
              hasCursor
            />
          </div>
          <div className="title-header">
            <HeaderTitle>Portal do Proprietário</HeaderTitle>
          </div>
          {logged && <ProfileMenu />}
        </FlexItem>
        <FlexItem flex height="100%" width="100%">
          <Grid container justifyContent="flex-end" style={{paddingBottom: "20px"}}>
              <Grid item xl={2} md={2} xs={6}>
                <Link onClick={() => navigate("/atualizacao-cadastral")} style={{cursor: "pointer", color: "rgb(251, 176, 22)", textDecorationColor: "rgb(251, 176, 22)"}}>Atualize seu cadastro aqui!</Link>
              </Grid>
          </Grid>
        </FlexItem>
      </HeaderStyle>
    </HeaderImage>
  );
};

export default Header;
