export const formatDate = (date) => {
  const dateFull = new Date(date);
  const d = dateFull.getDate();
  const m = dateFull.getMonth() + 1;
  const y = dateFull.getFullYear();
  return `${d}/${m}/${y}`;
};

export const formatNasc = (date) => {
  const value =
    date.slice(0, 2) + '/' + date.slice(2, 4) + '/' + date.slice(4, 8);
  return value;
};

// Método para ordernar valores alfanumérico e pode ser utilizado no sort do array
export const sortAlphanumeric = (valueA, valueB) => {
  if(!isNaN(Number(valueA)) && !isNaN(Number(valueB))){
    const an = Number(valueA);
    const bn = Number(valueB);

    if(an > bn){ 
      return 1;
    } 
    
    if(an < bn){
      return -1;
    } 
    return 0;
  }

  if(isNaN(Number(valueA)) && !isNaN(Number(valueB))){
    return -1;
  }

  if(isNaN(Number(valueB)) && !isNaN(Number(valueA))){
    return 1;
  }

  if(valueA > valueB){ 
    return 1;
  } 
  
  if(valueA < valueB){
    return -1;
  } 
  return 0;
};

export const validateEmail = email => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const validarCPF = cpf => {
  var soma;
  var resto;
  soma = 0;
  
  if (cpf === "00000000000") return false;

  for (let i=1; i<=9; i++) soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11))  resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10)) ) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if ((resto === 10) || (resto === 11))  resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11) ) ) return false;
  return true;
}