import { useEffect, useState, useContext } from "react";
import { SectionTitle, FlexItem, Text, Input } from '../../styles';
import { colors } from '../../theme';
import ArrowLeftOutlined from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlined from '@mui/icons-material/ArrowRightOutlined';
import UserContext from "../../context/UserContext";
import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Button, 
    Grid, 
    MenuItem, 
    Select, 
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Snackbar,
    Alert,
    AlertTitle,
    Divider
} from '@mui/material';    
import { getReportOwners, getEmailsOwners } from "../../services/owner";
import { getUH } from '../../services/uh';
import { sortAlphanumeric } from '../../utils/index';
import { Select as SelectComp } from '../../components';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';    

const OwnerReport = () => {
    const { state, setState } = useContext(UserContext);
    const [reportData, setReportData] = useState(null);
    const [findOwner, setFindOwner]  = useState({
        nome: null,
        conectante: null,
        uh: null,
        ultimoAcesso: null
    });
    const [listUH, setListUH] = useState([]);
    const [openMessage, setOpenMessage] = useState(false);
    const [openDialog, setOpenDialog] = useState({
        open: false,
        data: null
    });
    const [pages, setPageSelected] = useState({
        pageSelected: 1,
        totalPages: 1
    });

    const listPage = () => {
        const listSelect = [<MenuItem value="1">1</MenuItem>];

        if(pages.totalPages <= 1)
            return listSelect;

        for(let i = 2; i <= pages.totalPages; i++){
            listSelect.push((
                <MenuItem value={i}>{i}</MenuItem>
            ));
        }
        return listSelect;
    }

    const selectPage = (pageSelected) => {
        setPageSelected({...pages, pageSelected});

        
        getReportOwners(pageSelected, getQueryString()).then(({data}) => {
            setReportData(data);
        });
    }

    const handleClose = () => {
        setOpenDialog({ open: false });
    };

    const getQueryString = () => {
        let queryString = ''

        if(findOwner.nome)
            queryString  = `nome=${findOwner.nome}`;
        else
            queryString = `nome`;
        
        if(findOwner.conectante)
            queryString += `&conectante=${findOwner.conectante}`;
        else
            queryString += `&conectante`;
        
        if(findOwner.uh)
            queryString += `&uh=${findOwner.uh}`;
        else
            queryString += `&uh`;
        
        if(findOwner.ultimoAcesso)
            queryString += `&ultimo_acesso=${findOwner.ultimoAcesso}`;
        else {
            queryString += `&ultimo_acesso`;
        }

        return queryString;
    }

    const getReport = () => {
        getReportOwners(pages.pageSelected, getQueryString()).then(({data}) => {
            setReportData(data);

            let totalPages = Math.ceil((data.total / 10));
            totalPages = totalPages < 1 ? 1 : totalPages;
            setPageSelected({...pages, totalPages});
        });
    }

    const handleInputDateChange = (e) => {
        const { value } = e.target;

        const newV = dayjs(value, 'YYYY-MM-DD').format('DDMMYYYY');
        setFindOwner({... findOwner, ultimoAcesso: newV});
    };

    const getListUH = () => {
        getUH().then((res) => {
            let uhs = res.data.map(x => {
              return {descricao: x.descricao, id: x.id};
            });

            uhs.sort((a, b) => sortAlphanumeric(a.descricao, b.descricao));
            uhs.unshift({descricao: "Selecione", id:""});
            setListUH(uhs);
        });
    }

    const handleUhChange = (e) => {
        let value = e.target.value === 'Selecione' ? null : e.target.value;
        setFindOwner({... findOwner, uh: value});
    };

    const clickDialog = item => {
        setOpenDialog({
            open: true,
            data: item
        });
    }

    const clickCopyEmail = () => {
        getEmailsOwners(pages.pageSelected, getQueryString()).then(({data}) => {
            navigator.clipboard.writeText(data.join(';'));
            setOpenMessage(true);
        });
    }

    useEffect(() => {
        setState({
            ...state,
            breadcrumb: [
              {
                text: "Home",
                link: "/",
              },
              { text: "Área Administrativa", link: "/area-administrativa" },
              { text: "Relatório de proprietários" },
            ],
        });
        
        getListUH();
        getReport();
    }, []);

    return (
        <Grid container spacing={2}>
            <Snackbar open={openMessage} autoHideDuration={15000} 
                onClose={() => setOpenMessage(false) } 
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                <Alert severity="success">
                    <AlertTitle>E-mails copiados para a área de transferência</AlertTitle>
                </Alert>
            </Snackbar>
            <Grid item xs={12} md={12} style={{textAlign: 'center', marginTop: '30px'}}>
                <SectionTitle>Lista de Avisos</SectionTitle>
            </Grid>

            <Grid item xs={12} md={12} style={{marginTop: '40px'}}>
                <Button 
                    size="small" 
                    variant="contained" 
                    className="button-primary-default"
                    onClick={() => clickCopyEmail()}>
                    Copiar e-mails
                </Button>
            </Grid>

            <Grid item xs={12} md={12} style={{marginTop: '40px'}}>
                <FlexItem flex={true} mPadding='0 .5em'>
                    <div style={{ marginRight: '10px' }}>
                        <Text>Nome</Text>
                        <div style={{ marginLeft: 'auto' }}>
                            <Input
                            width='250px'
                            color={colors.primary}
                            type='text'
                            onChange={e => setFindOwner({... findOwner, nome: e.target.value})}
                            />
                        </div>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <Text>Conectante</Text>
                        <div style={{ marginLeft: 'auto' }}>
                            <Input
                            width='250px'
                            color={colors.primary}
                            type='text'
                            onChange={e => setFindOwner({... findOwner, conectante: e.target.value})}
                            />
                        </div>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <Text>Apto</Text>
                        <div
                            style={{
                            marginLeft: 'auto',
                            paddingRight: '10px'
                            }}
                        >
                            <SelectComp onChange={handleUhChange} items={listUH} />
                        </div>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <Text>Último acesso</Text>
                        <div style={{ marginLeft: 'auto' }}>
                            <Input
                            onChange={(e) => handleInputDateChange(e)}
                            placeholder={dayjs(new Date()).format('MM/DD/YYYY')}
                            width='250px'
                            color={colors.primary}
                            defaultValue={dayjs(new Date()).format('MM/DD/YYYY')}
                            type='date'
                            />
                        </div>
                    </div>
                </FlexItem>
            </Grid>

            <Grid item xs={12} style={{marginTop: '40px', textAlign: 'center'}}>
                <Button 
                    size="small" 
                    variant="contained" 
                    className="button-primary-default"
                    onClick={() => getReport()}
                    > 
                    Pesquisar
                </Button>
            </Grid>

            <Grid item xs={12} md={12}  >
                <TableContainer style={{marginTop: '20px'}}>
                    <Table sx={{ minWidth: 650 }} className='table-full-width'>
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">UH</TableCell>
                            <TableCell align="center">Data Nascimento</TableCell>
                            <TableCell align="center">CPF/CNPJ</TableCell>
                            <TableCell align="center">Último Acesso</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData && reportData.resultado.map(item =>
                            <>
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left" className="table-cell-no-border">
                                        {item.nome} <br />

                                        {item.dependentes.length > 0 && <Button style={{fontSize: '8px'}} onClick={() => clickDialog(item)}>Dependentes</Button>}
                                    </TableCell>
                                    <TableCell align="center" className="table-cell-no-border">
                                        {item.uhs}
                                    </TableCell>
                                    <TableCell align="center" className="table-cell-no-border">
                                        {item.data_nascimento != null && dayjs(item.data_nascimento, 'DDMMYYYY').format('DD/MM/YYYY')}
                                    </TableCell>
                                    <TableCell align="center" className="table-cell-no-border">
                                        {item.cpf_cnpj}
                                    </TableCell>
                                    <TableCell align="center" className="table-cell-no-border">
                                        {item.ultimo_acesso}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left" colspan="5">
                                        <b>Endereço: </b>
                                        {item.endereco} - {item.bairro}, {item.municipio} - {item.uf}, {item.cep}
                                    </TableCell>
                                </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    open={openDialog.open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                >
                    <DialogTitle id="alert-dialog-title"> Lista de dependentes </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="h6" gutterBottom style={{fontSize: '12px'}}>Proprietário: {openDialog.data?.nome}</Typography>
                            <Typography variant="h6" gutterBottom style={{fontSize: '12px'}}>UH: {openDialog.data?.uhs}</Typography>

                            <TableContainer style={{marginTop: '20px'}}>
                                <Table sx={{ minWidth: 650 }} className='table-full-width'>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Nome</TableCell>
                                        <TableCell align="center">Grau de parentesco</TableCell>
                                        <TableCell align="center">Data de nascimento</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {openDialog.data?.dependentes && openDialog.data.dependentes.map(item =>
                                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left">
                                                    {item.nome}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.grau_parentesco}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {item.nascimento}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Fechar</Button>
                    </DialogActions>
                </Dialog>
            </Grid>

            <Grid item xs={12} md={12} style={{marginBottom: '100px', textAlign: 'center'}}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{display: 'block'}}>
                    <Button variant="outlined" size="small" disabled={pages.pageSelected === 1} onClick={() => {selectPage(pages.pageSelected - 1)}}
                        style={{margin: '10px'}}>
                        <ArrowLeftOutlined />
                    </Button>
                    <Select id="demo-customized-select" value={pages.pageSelected} onChange={(event) => {selectPage(parseInt(event.target.value))}}>
                        {reportData && listPage().map((item) => item)}
                    </Select>
                    <Button variant="outlined" size="small" disabled={pages.pageSelected === pages.totalPages} onClick={() => {selectPage(pages.pageSelected + 1)}}
                        style={{margin: '10px'}}>
                        <ArrowRightOutlined />
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default OwnerReport;