import { api } from './api';

const getNoticeList = (page) => {
  const token = localStorage.getItem('token');

  return api.get('/admin/aviso', {
    headers: {
      Authorization: `Bearer ${token}`,
      pagina: page
    },
  });
};

const insertNotice = notice => {
    const token = localStorage.getItem('token');
  
    return api.post('/admin/aviso',
        notice,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        }
    );
};

const updateNotice = (id, notice) => {
    const token = localStorage.getItem('token');
  
    return api.put(`/admin/aviso/${id}`,
        notice,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
};

const deleteNotice = id => {
    const token = localStorage.getItem('token');
  
    return api.delete(`/admin/aviso/${id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
};

const downloadNotice = id => {
    const token = localStorage.getItem('token');
  
    return api.get(`/admin/download/aviso/${id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
        }
    );
};

export { getNoticeList, insertNotice, updateNotice, deleteNotice, downloadNotice };
