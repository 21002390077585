const colors = {
  white: 'white',
  primary: '#13395C',
  secondary: '#FBB016',
  gray: '#EEEEEE',
  red: '#8D0B0B',
  green: '#0B8D18',
  darkGray: '#444',
  lightGray: '#7A7A7A'
};
const fontSize = {
  title: '40px',
  titleSection: '30px',
  label: '20px',
  text: '16px',
  subtext: '14px',
  footNote: '12px'
};
const weight = {
  light: 200,
  semibold: 300,
  regular: 400,
  medium: 500,
  bold: 600
};

export { colors, fontSize, weight };
