import { api } from './api';

const getBankSlipList = (page) => {
  const token = localStorage.getItem('token');

  return api.get('/admin/processoBoleto', {
    headers: {
      Authorization: `Bearer ${token}`,
      pagina: page
    },
  });
};

const insertBankSlip = notice => {
    const token = localStorage.getItem('token');
  
    return api.post('/admin/processoBoleto',
        notice,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        }
    );
};

const updateBankSlip = (id, notice) => {
    const token = localStorage.getItem('token');
  
    return api.put(`/admin/processoBoleto/${id}`,
        notice,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
};

const deleteBankSlip = id => {
    const token = localStorage.getItem('token');
  
    return api.delete(`/admin/processoBoleto/${id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
};

const downloadBankSlip = id => {
    const token = localStorage.getItem('token');
  
    return api.get(`/admin/download/processoBoleto/${id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
        }
    );
};

const separateFilesBankSlip = id => {
    const token = localStorage.getItem('token');
  
    return api.post(`/admin/processoBoleto/dividir/${id}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

const getBankSlipListOwner = (bankSlipId, page) => {
    const token = localStorage.getItem('token');
  
    return api.get(`/admin/processoBoleto/${bankSlipId}/boleto`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                pagina: page
            }
        }
    );
};

const enableBankSlipSendEmail = (bankSlipOwnerId, enable) => {
    const token = localStorage.getItem('token');
  
    return api.put(`/admin/boleto/${bankSlipOwnerId}`,
        {
            envio_email: enable
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
};

const sendEmailBankSlipUH = (uh, referenceMonth) => {
    const token = localStorage.getItem('token');
  
    return api.post(`/admin/envioBoleto/${uh}`,
        {
            mesano: referenceMonth
        },
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
};

const downloadBankSlipUH = id => {
    const token = localStorage.getItem('token');
  
    return api.get(`/admin/boleto/${id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
        }
    );
};

export { 
    getBankSlipList, 
    insertBankSlip, 
    updateBankSlip, 
    deleteBankSlip, 
    downloadBankSlip,
    getBankSlipListOwner,
    enableBankSlipSendEmail,
    separateFilesBankSlip,
    sendEmailBankSlipUH,
    downloadBankSlipUH
};
